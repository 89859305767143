import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  container,
  description,
  hexToRgb,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const registerStyle = (theme) => ({
  backgroundImage: {
    ...backgroundImage,
    height: "550px",
    [theme.breakpoints.down("sm")]: {
      height: "900px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "1000px",
    },
  },
  backgroundImageText,
  closed: {
    ...description,
    ...textCenter,
    color: whiteColor,
  },
  description: {
    ...description,
    textAlign: "center",
    color: whiteColor,
  },
  descriptionSmall: {
    ...description,
    textAlign: "center",
    fontSize: ".9rem",
    marginBottom: "20px",
    color: whiteColor,
  },
  registerItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  registerButton: {
    margin: "0 auto",
  },
  container,
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
  },
  subtitle: {
    ...title,
    color: whiteColor,
    textAlign: "center",
    marginBottom: "10px",
  },
  title: {
    ...title,
    textAlign: "center",
    color: whiteColor,
  },
})

export default registerStyle
