import React from "react"

// gatsby libraries
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"

// page styles
import registerStyle from "assets/jss/material-kit-pro-react/views/registrationPageSections/registerStyle.jsx"
const useStyles = makeStyles(registerStyle)

export default function Sectionregister() {
  const { manualRegistrationForm, registerBackground } = useStaticQuery(graphql`
    query getmanualRegistrationForm {
      manualRegistrationForm: file(
        relativePath: {
          eq: "registration/documents/chilimania-manual-registration.pdf"
        }
      ) {
        publicURL
        name
      }
      registerBackground: file(
        relativePath: { eq: "backgrounds/trophies.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={registerBackground.childImageSharp.gatsbyImageData}
        alt="Register Now Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10} md={8}>
              <h2 className={classes.title}>Register Now</h2>
              <p className={classes.closed}>
                <b>*Please Note:</b> On-line registration for Chilimania 2024 is
                now <b>CLOSED</b>. If you are a CASI cook, please contact{" "}
                <a href="mailto:cookoff@chilimania.com">
                  cookoff@chilimania.com
                </a>
                .
              </p>
              <p className={classes.description}>
                On-line registration is preferred, but if you are unable to
                complete registration online a PDF mail-in registration form is
                available for download below. Additional documents on the
                contest rules and cook's letter are also available below if you
                did not recieve them after completing your registration.
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center" alignItems="flex-start">
            <GridItem md={4} sm={10} xs={10} className={classes.registerItem}>
              <h4 className={classes.subtitle}>Chilimania Registration</h4>
              <p className={classes.descriptionSmall}>
                Looking to register online? <br />
                Click below to begin your online registration form.
              </p>
              {/* <Link to="/register-online"> */}
              <Button
                className={classes.registerButton}
                color="primary"
                disabled
              >
                <ShoppingCart />
                {/* Register Now */}
                Registration Closed
              </Button>
              {/* </Link> */}
            </GridItem>
            <GridItem md={4} sm={10} xs={10} className={classes.registerItem}>
              <h4 className={classes.subtitle}>
                Chilimania Manual Registration
              </h4>
              <p className={classes.descriptionSmall}>
                Need to mail in your registration? <br />
                Click below to download a PDF registration form.
              </p>
              {/* <a href={manualRegistrationForm.publicURL}> */}
              <Button
                className={classes.registerButton}
                color="primary"
                disabled
              >
                <PictureAsPdfIcon />
                {/* Download */}
                Registration Closed
              </Button>
              {/* </a> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
